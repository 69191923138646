<template>
    <div class="sales-content">
        <div class="sales-feature">
            <div class="feature-left">
                <el-radio-group class="radio-group" v-model="chooseTime" @change="chooseTimeChange" size="small" :border="false">
                    <el-radio-button label="日榜"></el-radio-button>
                    <el-radio-button label="周榜" ></el-radio-button>
                    <el-radio-button label="月榜" ></el-radio-button>
                </el-radio-group>
                <el-date-picker
                        v-show="chooseTime==='日榜'"
                        v-model="value1"
                        value-format="yyyy-MM-dd"
                        type="date"
                        @change="dailyList()"
                        :picker-options="daypickerOptions"
                        placeholder="选择日期">
                </el-date-picker>
                <el-date-picker
                        v-show="chooseTime==='周榜'"
                        v-model="weekDate"
                        type="week"
                        format="yyyy 第 WW 周"
                        value-format="yyyy-MM-dd"
                        @change="dailyList()"
                        :picker-options="pickerOptions"
                        placeholder="选择日期">
                </el-date-picker>
                <el-date-picker
                        v-show="chooseTime==='月榜'"
                        v-model="month"
                        value-format="yyyy-MM-dd"
                        type="month"
                        @change="dailyList()"
                        :picker-options="monthPickerOptions"
                        placeholder="选择日期">
                </el-date-picker>
<el-input clearable @change="getLivePeople('','','search')"  prefix-icon="el-icon-search" class="el-int"  placeholder="请输入电商达人" v-model="keyword"></el-input>

            </div>

        </div>
        <el-table :data="salesTable" style="width: 100%; flex: 1" size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333'}"
                  :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column prop="rank" label="排行" min-width="80"></el-table-column>
            <el-table-column prop="nickname" label="账号" align="left" min-width="360">
                <template slot-scope="scope">
                    <div class="sowing">
                        <img :src="scope.row.avatar" alt="">
                        <span class="sowing-name">{{scope.row.nickname}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="product_category" label="主推类目" align="center"  ></el-table-column>
            <el-table-column prop :label="chooseTime=='日榜'?'日直播销售额':chooseTime=='周榜'?'周直播销售额':'月直播销售额'" align="center" :formatter="saleAmountFormat"  ></el-table-column>
            <el-table-column prop :label="chooseTime=='日榜'?'日直播销售量':chooseTime=='周榜'?'周直播销售量':'月直播销售量'" align="center" :formatter="saleCountFormat"  ></el-table-column>
            <el-table-column prop label="销售客单价" align="center" :formatter="topAudienceCountFormat"  > </el-table-column>
<!--            <el-table-column  label="直播场次" align="center" prop="live_count_30"  v-if="isSearch"> </el-table-column>
            <el-table-column  label="直播平均场观" align="center" prop="live_pv_medium"  v-if="isSearch"> </el-table-column>
            <el-table-column  label="场均销售额" align="center" prop="live_average_amount_30_v2_text"  v-if="isSearch"> </el-table-column>-->
            <el-table-column label="粉丝数" align="center" >
                <template slot-scope="scope">
                    <span>{{fansFormat(scope.row)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" min-width="160">
                <template slot-scope="scope" >
                    <div class="operation" @click="gotoHotBroadcaster(scope.row)">
                        <el-button type="primary" class="view">查看详情</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center" background style="margin: 0 0 10px 0"
                :current-page="salesPages.currentPageNum"
                :page-size="salesPages.eachPageNum"
                layout="prev, pager, next, jumper"
                v-show="salesTable.length > 0"
                :total="salesPages.total"
                @current-change="salesCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import dayjs from "dayjs";
    import {endDate , defaultDay} from "@/utils/common";

    export default {
        name: "DsSalesRankModule",
        data() {
            let _minTime = null;
            let _maxTime = null;
            return {
                loading:false,
                //日榜时间选择器
                isSearch:false,
                keyword:'',
                active_time: '',
                chooseTime:'日榜',
                value1:'',
                // 一周
                weekDate:'',
                daypickerOptions: {
                    disabledDate(time) {
                        //昨日之前的日期才可以选择
                        let day = dayjs().subtract(1, "day").unix() * 1000
                        return time.getTime() > endDate()
                    }
                },
                pickerOptions: {
                    // onPick(time) {
                    //     console.log("日期--", time);
                    //     if (!time.maxDate) {
                    //         let timeRange =7 * 24 * 3600 * 1000;
                    //         _minTime = time.minDate.getTime(); // 最小时间
                    //         _maxTime = time.minDate.getTime() + timeRange; // 最大时间
                    //     } else {
                    //         _minTime = _maxTime = null;
                    //     }
                    // },
                    disabledDate(time) {
                        //设置上周日之后的日期禁止选择
                        let now = (dayjs().day(0).unix() - 24 * 3600) * 1000
                        return time.getTime() > now
                    }
                },
                monthPickerOptions:{
                    //前一个月的月份才可以选
                    disabledDate(time) {
                        let month = dayjs().month()
                        return time.getMonth() > month - 1
                    }
                },
                month:'',//月榜
                //电商达人销售数据
                salesTable: [
                    {
                        rank:'',
                        nickname: '',
                        avatar:'',
                        mcate_name:'',
                        daily_sale_amount:'',
                        daily_sale_count:'',
                        follower_count:'',
                        daily_avg_audience_count:''
                    }
                ],
                //分页
                salesPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                }
            };
        },
        mounted(){
            this.getLivePeople()
        },
        methods:{
            fansFormat(row) {
                if (Number(row.follower_count) > 10000) {
                    return (Number(row.follower_count)/10000).toFixed(2) + "w";
                }
                return row.follower_count;
            },
            // 直播销售额
            saleAmountFormat(row) {
                if (this.chooseTime == "日榜") {
                    return row.sales_volume_text;
                } else if (this.chooseTime == "周榜") {
                    return row.sales_volume_text;
                } else {
                    return row.sales_volume_text;
                }
            },
            // 直播销售量
            saleCountFormat(row) {
                if (this.chooseTime == "日榜") {
                    return row.sales_text;
                } else if (this.chooseTime == "周榜") {
                    return row.sales_text;
                } else {
                    return row.sales_text;
                }
            },
            // 销售客单价
            topAudienceCountFormat(row) {
                if (this.chooseTime == "日榜") {
                    return row.average_price;
                } else if (this.chooseTime == "周榜") {
                    return row.average_price;
                } else {
                    return row.average_price;
                }
            },
            chooseTimeChange(val){
            //    榜单切换事件
                if (val == '日榜'){
                    this.getLivePeople('day');
                }else if(val == '周榜'){
                    this.getLivePeople('week');
                }else {
                    this.getLivePeople('month');
                }
            },
            // 日榜
            dailyList(){
                if (this.chooseTime == '日榜'){
                    this.getLivePeople('day',this.value1);
                } else if(this.chooseTime == '周榜'){
                    this.getLivePeople('week',this.weekDate);
                }else{
                    this.getLivePeople('month',this.month);
                }

            },
            // 获取电商达人列表
            getLivePeople(date,datetime,type){
                if(type=='search'){
                    this.salesPages.currentPageNum = 1
                }
                let param = {
                    keyword:this.keyword,
                    page: this.salesPages.currentPageNum,
                    limit: this.salesPages.eachPageNum,
                };
                if(date){
                    param['date'] = date;
                } else {
                    param['date'] = defaultDay();
                }
                if(date){
                    param['datetime'] = datetime;
                }
                this.$httpStudent.axiosGetBy(this.$api.getLivePeopleList,param,res => {
                    if(this.keyword){
                        this.isSearch = true;
                    }else{
                        this.isSearch = false;
                    }
                    if (res.code == 200) {
                        this.salesTable = res.data.list
                        this.salesPages.total = res.data.page_info.totalCount;
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                            duration: 1000
                        });

                    }
                })
            },
            // 电商达人查看详情
            gotoHotBroadcaster(row){
                // console.log(row)
                this.$router.push({
                    path: '/student/ds/sales/rank/details',
                    query:{
                        douyinuser_id:row.author_id
                    }
                })
            },
            // 分页
            salesCurrentChange(val){
                this.salesPages.currentPageNum = val;
                if (this.chooseTime == '日榜'){
                    this.getLivePeople('day');
                } else if(this.chooseTime == '周榜'){
                    this.getLivePeople('week');
                }else{
                    this.getLivePeople('month');
                }
            },
        }
    }
</script>

<style scoped lang="scss">
        .el-int{
            width: 260px;
            margin-left: 20px;
        }
        .sales-content{
            padding-top: 15px;
            background-color: white;
        height: 100%;
        .sales-feature{
            display: flex;
            justify-content: space-between;
            padding: 0 0 20px 20px;
            border-bottom: 1px solid #eaeaea;
            .feature-left{
                .el-select {
                    margin-left: 10px;
                }
                .radio-group{
                   ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                        background-color: #0824CE;
                        border-color: #0824CE;
                    }
                    ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner:hover{
                        color: #fff;
                    }
                    ::v-deep.el-radio-button__inner:hover {
                        color: #0824CE;
                    }
                }
            }
            .feature-right{
                .updata-time {
                    margin-right: 10px;
                    color: #999;
                }
            }
        }
        ::v-deep.el-table td {
            border-bottom: 8px solid #ebeef5;
        }
        ::v-deep.el-table{
            display: flex;
            flex-direction: column;
            /*height: calc(100% - 114px);*/
        }
        ::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
            background-color: transparent;
        }
        ::v-deep.el-table--medium td, ::v-deep.el-table--medium th{
            padding: 12px 9px;
        }
        ::v-deep.el-table__footer-wrapper,::v-deep.el-table__header-wrapper{
            overflow: initial;
        }
        ::v-deep.el-table__body-wrapper{
           min-height:654px;
        }
        ::v-deep.el-table th{
            padding: 14px 0 ;
        }
        .sowing{
            display: flex;
            align-items: center;
            /*width: 30px;*/
            height: 30px;
            img{
                display: inline-block;
                height:30px;
                width: 30px;
                margin-right: 10px;
                border-radius: 50%;
            }
            .sowing-name{
                max-width: 260px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        .operation{
            ::v-deep.el-button--primary {
                 color: #FFF;
                 background-color: #0824CE;
                 border-color: #0824CE;
             }
            ::v-deep.el-button--primary:focus, .el-button--primary:hover {
                background: #495df1;
                border-color: #495df1;
                color: #FFF;
            }
            ::v-deep.el-button{
                padding: 8px 13px;
                border-radius: 2px;
            }
        }
        ::v-deep.el-date-editor.el-input, ::v-deep.el-date-editor.el-input__inner{
            margin-left: 10px;
        }

    }
</style>
